import { useState, useEffect } from "react";
import { WINDOW_SIZES } from "../constants/window-sizes";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
        isMobile: width < WINDOW_SIZES.tablet,
    };
}
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

// TODO: remove default export and use named export
export default useWindowDimensions;