import PortalAnalytics from "analytics/PortalAnalytics";

import { isEmpty } from "utils/form/form-utils";
import { BookingsPlatformAnalyticsWidget } from "components/supplier-database/bookings-platform/analytics/BookingsPlatformAnalytics.types";

export enum AnalyticsSlugsBookingsPlatform {
    BOOKINGS_PLATFORM__WEBSITE_LINK_CLICK = "booking_platform__website_link_click",
    BOOKINGS_PLATFORM__GMAP_LINK_CLICK = "bookings_platform__gmap_link_click",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_HOTELS_LIST_SELECTED = "bookings_platform__currency_conversion_on_hotels_list_selected",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_HOTELS_LIST_OPENED = "bookings_platform__currency_conversion_on_hotels_list_opened",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_HOTELS_LIST_CLOSED = "bookings_platform__currency_conversion_on_hotels_list_closed",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_RATES_PAGE_SELECTED = "bookings_platform__currency_conversion_on_rates_page_selected",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_RATES_PAGE_OPENED = "bookings_platform__currency_conversion_on_rates_page_opened",
    BOOKINGS_PLATFORM__CURRENCY_CONVERSION_ON_RATES_PAGE_CLOSED = "bookings_platform__currency_conversion_on_rates_page_closed",
    BOOKINGS_PLATFORM__COPY_RATE_CLICKED = "bookings_platform__copy_rate_clicked"

}

export const bookingsPlatformTrack = (
    name: AnalyticsSlugsBookingsPlatform,
    payload: BookingsPlatformAnalyticsWidget | undefined
) => {
    if (!payload) {
        /*We can send an empty payload. Some actions do not need something else except the name of the action*/
        return;
    }
    PortalAnalytics.track(name, payload);
};
